import React from "react";
/* import { graphql, Link } from "gatsby"; */
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

/* import PropTypes from "prop-types"; */

/* function filterUniqueBibleBooks(edges) {
  let uniqueBibleBooks = [];
  let flags = {};
  for (let i = 0; i < edges.length; i++) {
    let englishName = edges[i]["node"]["englishName"];
    if (flags[englishName]) {
      continue;
    }
    flags[englishName] = true;
    uniqueBibleBooks.push(edges[i]);
  }
  return uniqueBibleBooks;
} */

/* export default function IndexPage({ data }) { */
  export default function IndexPage() {
/*   const uniqueEdge = filterUniqueBibleBooks(data.allChapterJson.edges);
  const bibleBooksDetail = uniqueEdge.map((edge) => {
    return {
      title: edge.node.bibleBookTitle,
      enlgishName: edge.node.englishName,
      testament: edge.node.testament,
    };
  });
  const oldBibleBooksDetail = bibleBooksDetail.filter(
    (book) => book.testament == "old"
  );
  const newBibleBooksDetail = bibleBooksDetail.filter(
    (book) => book.testament == "new"
  ); */
  return (
    <Layout>
      <SEO
        keywords={[
          `聖經`,
          `聖經經文`,
          `聖經和合本`,
          `聖經查詢`,
          `繁體聖經`,
          `繁體新約聖經`,
          `繁體舊約聖經`,
        ]}
        title="聖經資源"
        description="聖經資源包括舊約聖經及新約聖經，讓你研讀神的話語，傳揚見證主耶穌基督的福音。"
      />

      <section>
        <h1 className="text-center m-2 text-3xl font-bold m-3">聖經</h1>
        <div className="m-2">
          <h2 className="text-center m-2 text-2xl font-bold">舊約聖經</h2>
          <p className="m-2 text-xl">
            舊約聖經總共有三十九卷。按歷史來說，舊約聖經是指在主耶穌誕生之前已寫成及被輯錄成書的「約」，約在400BC前所寫的。
          </p>
{/*           <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {oldBibleBooksDetail.map((bibleBookDetail, index) => (
              <h3
                key={index}
                className="bg-red-600 text-white hover:bg-white hover:text-red-600 text-center p-2 m-1 text-xl"
              >
                <Link to={`/bibleBooks/${bibleBookDetail.enlgishName}/1/`}>
                  {bibleBookDetail.title}
                </Link>
              </h3>
            ))}
          </div> */}
        </div>

        <div className="m-2">
          <h2 className="text-center m-2 text-2xl font-bold">新約聖經</h2>
          <p className="m-2 text-xl">
            新約聖經總共有二十七卷。按歷史來說，舊約聖經是指在主耶穌誕生之後寫成及被輯錄成書的「約」，約在1-100AD期間所寫的。
          </p>
{/*           <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
            {newBibleBooksDetail.map((bibleBookDetail, index) => (
              <h3
                key={index}
                className="bg-blue-600 text-white hover:bg-white hover:text-blue-600 text-center block p-2 m-1 text-xl"
              >
                <Link to={`/bibleBooks/${bibleBookDetail.enlgishName}/1/`}>
                  {bibleBookDetail.title}
                </Link>
              </h3>
            ))}
          </div> */}
        </div>
      </section>
    </Layout>
  );
}

IndexPage.propTypes = {
/*   data: PropTypes.shape({
    allChapterJson: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            bibleBookTitle: PropTypes.string,
            englishName: PropTypes.string,
            testament: PropTypes.string,
          }),
        })
      ),
    }),
  }), */
};

export const query = graphql`
  query {
    allChapterJson {
      edges {
        node {
          bibleBookTitle
          englishName
          testament
        }
      }
    }
  }
`;
